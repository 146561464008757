import React, { useEffect } from "react";
import axios from "axios";

const ThankYouGse = () => {
  if (typeof window === "undefined") {
    var params = new URLSearchParams({ f: "bar", l: "bar" });
  } else {
    var params = new URLSearchParams(window.location.search);
  }

  useEffect(() => {
    sendCustomerEmail();
  }, []);

  //
  //
  //Send Customer Email
  //
  const sendCustomerEmail = async (e) => {
    try {
      await axios.post(
        "https://consult-gse-partnership.netlify.app/.netlify/functions/gse-customer-email",
        {
          //'https://consult-gse-partnership.netlify.app/api/gse-customer-email'//
          ///http://localhost:8888/.netlify/functions/gse-customer-email///
          name: params.get("f") + " " + params.get("l"),
          email: params.get("e"),
          gseId: params.get("g"),
          pin: params.get("p"),
          company: params.get("c"),
        }
      );

      //Do something when request is successful
    } catch (e) {
      console.log("Message Sent");
    }
  };

  return (
    <div>
      <main class="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-12 lg:mt-16">
        <div class="text-center">
          <img
            class="mx-auto"
            src="https://imgix.cosmicjs.com/1fac8f20-f7d5-11ec-8f84-3f01a4a22fe2-GSE-partnership-lockup-long.svg"
            alt="logo"
          />
          <div className="mx-auto flex w-6/12 mb-4"></div>
          <h2 class="text-2xl md:text-4xl tracking-tight md:leading-12 font-extrabold text-blue-900 sm:text-5xl sm:leading-none md:text-6xl">
            Your Submission Was Received Successfully!
          </h2>

          <div class="inline-flex justify-center">
            <div class="rounded-md">
              <div class="mt-14 rounded-md sm:flex-shrink-0">
                <a
                  href="mailto:Gse@consultfgc.com"
                  class="w-full px-20 py-5 text-lg font-medium rounded-md text-white bg-gradient-to-l from-secondaryBlue to-primaryBlue hover:opacity-70"
                >
                  Email CFGC If You Made a Mistake
                </a>
              </div>

              <div class="mt-14 rounded-md sm:flex-shrink-0">
                <a
                  href="/consult-gse-partners/gse-customer-request"
                  class="w-full px-20 py-5 text-lg font-medium rounded-md text-white bg-gradient-to-l from-purple-900 to-purple-800 hover:opacity-70"
                >
                  Return To Customer Input Form
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ThankYouGse;
